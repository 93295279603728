.appLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .cursorPointer {
    cursor: pointer;
  }
}

@media (min-width: 768px) {
  .appLinks {
    flex-direction: row;
  }
}

.downloadAppContainer {
  gap: 40px;

  .headerContainer {
    color: #1a1819;
    font-size: 30px;
    margin: 0px;
    font-weight: 700;
  }

  .textContent {
    color: #333;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin: 0px;
  }

  .footerTextContent {
    font-size: 14px;
    margin: 0px;
    color: #000;
  }
}

.gapM {
  gap: 20px;
}

.dFlexCol {
  display: flex;
  flex-direction: column;
}

.centerItems {
  align-items: center;
  justify-content: center;
}

.textCenter {
  text-align: center;
}

.textBold {
  font-weight: 700;
}